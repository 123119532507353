<template>

  <div id="app">
    <!-- <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"> -->

    <link rel="preconnect" href="https://fonts.googleapis.com">

<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin> 
<meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, shrink-to-fit=no" />

<link href="https://fonts.googleapis.com/css2?family=Mochiy+Pop+P+One&display=swap" rel="stylesheet">

<!-- <Menu /> -->
<div id="topmenu" >
  <van-nav-bar
  title="Documents"
/>
</div>
    <div id="contentscroll">

      <transition
        name="fade"
        mode="out-in"
      >
        <router-view/>
        
      </transition>
    </div>
    <!-- <Menu msg="Welcome to Your Vue.js App"/> -->
  </div>
</template>
<script>
import helper from './helper.js'
import Sortable from 'sortablejs';
// import { Popup } from 'vant';


window.Helper = helper;
export default {
  name: 'App',
  components: {
  },
  data: function () {
    return {
    }
  },
  created(){
  },
  mounted(){
    
		
    
  },
  methods:{}
}

// function handleSSE(event) {
//   var data = JSON.parse(event.data);
//     console.log(data);
//   if (data.event == 'job_done') {
//     // Handle the event data here
//     console.log(data.data);
//   }
// }
// var source = new EventSource(process.env.VUE_APP_API_URL+'sse.php?pid=45');
// source.addEventListener('message', handleSSE);

</script>
<style>
/* #app {
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;    text-transform: uppercase;
}
body {
  margin: 0;
}
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
} */

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, Menu, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
Menu, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
  overflow-x: hidden;
  overflow: none;
  background: #f3f4f8;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
border-collapse: collapse;
	border-spacing: 0;
}
* {
    touch-action: manipulation;
}
.row {
  --bs-gutter-x:0 !important;
}
/* .container {
  padding-right: 0;
  padding-left: 0;
} */
body, html {
  overflow: hidden;
  background:white;
}

@media screen and (max-width: 600px) {
.bodyscroll {
  overflow-y: scroll;
  height: 100vh;
    top: 45px;
    left: 0;
    position: fixed;
    width: 100%;
    background: #f3f4f8;
}
}
.page-container {
  padding:10px;
}
.van-notify--error {
  background-color:red !important;
  top: 46px !important;
}


 @media screen and (min-width: 601px) {
  body {
    /* line-height: 1; */
    /* overflow-x: scroll !important; */
    /* background:white; */
    
overflow: scroll;
    height: 100vh;
  }
} 

body {
    /* line-height: 1; */
    /* overflow-x: scroll !important; */
    /* background:white; */
    /* background:#181922 !important;; */
    
overflow: scroll;
    height: 100vh;
  }
  v-cloak {
    display:none;
  }
  #topmenu {
    /* position: fixed; */
    /* top:0px; */
    width: 100%;
  }
  /* body {
    overflow:hidden
  } */
  body.iOS #topmenu {
    margin-top:10px;
  }
  
  body.android #topmenu span {
    float: right;margin: 18px 20px 0px 0px;
  }
  
  body.android #topmenu > div {
    margin: 14px 15px 0px;
  }
  body.android #contentscroll {
    margin-top:0;
  }
  #playlist-bottom {
    /* position: absolute;
    bottom:0;
    width: 100%; */
    /* z-index: 888; */
    background-color: #f3f4f8;
  }
  #playlist-bottom img {
    height:90px;
    /* margin:2px; */
  }
  #playlist-bottom div.van-info {
    
    padding: 3px;
    background-color: black;
  }
  #playlist-bottom div {
    display: inline-block;
    font-size: 1rem;
    padding: 10px;
    overflow: hidden;
  }
  #audioplayersimple {
   /* position: absolute; */
   z-index: 1;
    /* left: -417px;; */
  }
    #playingpopup #playlist-bottom-rest {
    display: none;
  }
  #playingpopup.openfull #playlist-bottom {
    display: none;
  }
  #playingpopup.openfull #playlist-bottom-rest {
    display: block;
  }
  #closeMenu {

  }

  #playingpopup {
    background-color: initial;
  }

  #playingpopup.openfull {
    background-color: #fff;
  }
  #playingpopup {
    overflow-y: initial;
  }
  #playingpopup.openfull {
    overflow-y: auto;
  }
  #clickmenu {
    float: right;margin:45px 20px 0 0
  }

  
@media only screen 
  and (min-device-width: 768px)  {
  
    #clickmenu {
    position: absolute;
    right:35vw;
    top:30px
  }
    #playingpopup {
      /* max-width: 482px;
    height: 11%;
    z-index: 2004;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
     */
    
     position: fixed;
    right: 0;
    left: auto;
    height: 90vh;
    width: 34vw;

    }
    .closeplayer {
      display: none;
    }
    #catssel {
      position: absolute;
      right:33vw;
      top:0;
    }
    #playingpopup.openfull {
      
    overflow-y: initial;
    z-index: 2001;
    right: 14px;
    top: 12px;
    bottom: 185px;
    }
    #playlist-bottom-rest {
      /* position: fixed;
      right:0;
      height: 90vh;
      width:20vw; */

      z-index: 999;
    box-shadow: -9px 5px 56px rgba(0, 0, 0, 0.5);
    }
    #contentscroll {
      width: 66vw;;
    }
  }
</style>
