import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  { path: '/',                       name: 'List',          meta: { transition: 'fade' },   component: () => import('../pages/List.vue')          }
]


window.routeslist = routes;
const FutureRoutes = []

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
