

var ErrorCodes = {
  ERR_BADDATA:101,
  ERR_ALREADY_EXISTS:102,
  ERR_FEATUREOFF:103,
  ERR_PHP:105,
  ERR_CORRUPTDATA:106,
  ERR_TIMEOUT:107,
  ERR_MISSING:108,
  ERR_SECURITY:109,
  ERR_TOOMANY: 110,
  ERR_REQUIRELOGIN:401,
  ERR_SPECIAL:112,
  ERR_NOTFOUND:113
}



const helper = {
 
  apicall: function (path, data, callback, failcallback) {
    window.faker.isEnabled = false;
    if (window.faker.isEnabled)
      return window.faker.call(data,callback,failcallback)

    var selfhelp = this;
    var headers = {'Content-Type': 'application/json'}
    // if (localStorage.getItem("JWT")) {
    //   //they have an open session
    //   headers = {
    //     "Authorization":"Bearer "+localStorage.getItem("JWT"),
    //     'Content-Type': 'application/json'
    //   }
    //   data.JWT = localStorage.getItem("JWT");
     
    // }
    //  else if (localStorage.getItem("forever_token")) {
    //   //need to go get new session
    //   data.forever_token = localStorage.getItem("forever_token");
    //   // data.tokenexpires = localStorage.getItem("tokenexpires");
    //   var currentTime = Date.now() / 1000;

    //   if (data.tokenexpires && data.tokenexpires < currentTime) {
    //     // console.log("token expired, trying to refresh");
    //     //go get new token
    //     selfhelp.apicall(process.env.VUE_APP_API_URL + "/login-forever-token",
    //       data,
    //       function(x) {
    //         console.log("redoing first call");
    //         localStorage.setItem("JWT", x.JWT);
    //         //call the same again (except its now authed)
    //         selfhelp.apicall(path, data, callback, failcallback);
    //       },
    //       function (e) {
    //         console.log("Error");
    //       }
    //     );
    //   }
    // }
    
    fetch(path, {
      method: "POST",
      // mode: 'no-cors',
      credentials: 'omit',
      headers: headers,
      body: JSON.stringify(data),
    })
      .then((res) => {
        switch(res.status){
        case ErrorCodes.ERR_REQUIRELOGIN:
          localStorage.removeItem("JWT")
          location.href = "/login";
          return false;
      }
        console.log(res.status);
        return res.json();
      })
      .then((data) => {
        callback(data);
      })
      .catch(function (e) {
        if (failcallback)
          failcallback(e);
        // console.error("error");
        console.error(e);
      });
  },
  gotoLink(link) {
    var data = {
      data: link,
      type: "Link"
    }
    invokeCSharpAction(JSON.stringify(data));
  },
  detectDevice() {
    if (localStorage.getItem("isAppX") == '1')
      return 'app';
    return 'webapp';
  },
  DetectLinks() {
    for (let index = 0; index < this.itemsList.length; index++) {
      if (this.itemsList[index].Text.substring(0, 4) == 'http') {
        return (this.itemsList[index].Text || "").replace(
          /([^\S]|^)(((https?\:\/\/)|(www\.))(\S+))/gi,
          function (match, space, url) {
            var hyperlink = url;
            if (!hyperlink.match('^https?:\/\/')) {
              hyperlink = 'http://' + hyperlink;
            }
            return space + '<a onclick="helper.gotoLink(\'' + hyperlink + '\');return false;">' + url + '</a>';
          }
        );
      }

    }
  }
}

export default helper

