import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vant from 'vant';

import 'vant/lib/index.css';

import { Tabbar, TabbarItem,Notify,PasswordInput,NumberKeyboard  } from 'vant';


Vue.use(Notify);
Vue.use(PasswordInput);
Vue.use(NumberKeyboard);
Vue.use(Tabbar);
Vue.use(TabbarItem);
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// import './styles/custom.scss'

// Import Bootstrap an BootstrapVue CSS files (order is important)
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap-icons/font/bootstrap-icons.css'

// import 'leaflet/dist/leaflet.css';

// import VueObserveVisibility from 'vue-observe-visibility'

/** To use Live PVP
import VueGun from 'vue-gun';
// import SEA from 'gun/sea'; // Required for SEA functions and user authentication 
Vue.use(VueGun, {
  // peers: ['gosolve.ideahero.app:9000/gun']
  peers: [
    "https://gun-manhattan.herokuapp.com/gun",
    "https://gunjs-mtl.herokuapp.com/gun"
  ]
});
*/

// Vue.use(VueObserveVisibility)
// import VSwitch from 'v-switch-case'
// Vue.use(VSwitch)


import AudioVisual from 'vue-audio-visual'
import AudioSpectrum from 'vue-audio-visual'

Vue.use(AudioVisual)
Vue.use(AudioSpectrum)
import { directive as vTouch } from 'v-touch';

Vue.directive('touch', vTouch);

// Make BootstrapVue available throughout your project

Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(Vant);
// import {Tabbar} from "vant"
// import {TabbarItem} from "vant"
import { Dialog } from 'vant';

Vue.use(Dialog);


Vue.config.productionTip = false
Vue.config.errorHandler = function (err, vm, info) {
//debugger;
  console.error(err);
  var msg = 'unknown';
  if (err) {
    msg = `Error: ${err.toString()}\nInfo: ${info}`;
  }
  // gtag('event', 'JS-Error', {
  //   'event_category': 'Errors',
  //   'event_label': msg
  //   });
  Notify({
    message: msg,
    style: {
      bottom: '0'
    }
  });
   setTimeout(function(){
      Notify.clear();
    },16000);
  // Notify(msg);
    // var elemDiv = document.createElement('div');
    // elemDiv.innerHTML = '<h3>'+msg+'</h3>';
    // elemDiv.id = 'err';
    // elemDiv.style = 'background-color:orange';
    // document.body.append(elemDiv);
    // setTimeout(function(){
    //   document.getElementById('err').remove();
    // },6000);
}
const script = document.createElement('script');
script.src = 'https://apis.google.com/js/client.js';
document.head.appendChild(script);

Vue.config.ignoredElements = [/^x-/];

// const  vueapp = new Vue({
//   router,
//   render: h => h(App)
// }).$mount('#app')
// require('./inobounce.js');

window.vueapp = new Vue({
  router,
  render: h => h(App),
  methods: {
  }
}).$mount('#app')

var rip = document.getElementsByClassName('loaderx');
if (rip && rip[0])
  rip[0].classList.remove("loaderx");

var  params = new URLSearchParams(document.location.search);
var appver = params.get("appver");
if (appver){
  localStorage.setItem('isApp',appver)
}
var isApp = (localStorage.getItem('isApp')) ? true: false;
// var userAgent = window.navigator.userAgent.toLowerCase(),
//     safari = /safari/.test( userAgent ),
//     ios = /iphone|ipod|ipad/.test( userAgent );

// var webapp = true;
// if( ios ) {
//     if ( safari ) {
//         //browser
//         //emulating UNITY
        
//     } else if ( !safari ) {
//         //webview
//         webapp = false
//     };
// } else {
//     //not iOS
// };



